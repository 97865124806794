import React, { useRef } from "react";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import IntroductionSection from "./components/IntroductionSection";
import InfoCards from "./components/InfoCards";
import CompletedProjects from "./components/CompletedProjects/CompletedProjects";
import { Route, Routes, useLocation } from "react-router-dom";
import ProjectsPage from "./Pages/ProjectsPage";
import Header from "./components/Header";
import MasterpiecesSection from "./components/MasterpiecesSection";
import ContactUsForm from "./components/ContactUsForm";
import MeetJSBExperts from "./components/MeetJSBExperts/MeetJSBExperts";
import InTheirOwnWords from "./components/InTheirOwnWords/InTheirOwnWords";
import ThankYouPage from "./Pages/ThankYouPage";
import Footer from "./components/Footer";
import EnquireNowButton from "./components/EnquireNowButton";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./Pages/PrivacyPage";
import HeaderVersion from "./components/HeaderVersion";

function App() {
  const location = useLocation();
  const homeTopRef = useRef(null);
  const aboutRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);
  const introductionRef = useRef(null);
  const projectsRef = useRef(null);
  const masterpiecesRef = useRef(null);
  const otherProjectsRef = useRef(null);
  const homeRef = useRef(null);
  const overviewRef = useRef(null);
  const amenitiesRef = useRef(null);
  const floorPlanRef = useRef(null);
  const locationRef = useRef(null);
  const galleryRef = useRef(null);
  const enquireRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const hasUTMParams = utmSource || utmMedium || utmId || gadSource;

  const scrollToSection = (ref, offset = 70) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };
  const baseUrl = "https://jsbhomemakers.com";
  const canonicalUrl =
    location.pathname === "/"
      ? baseUrl // Home page
      : `${baseUrl}/projects${location.pathname}`; // Project page

  console.log("Canonical URL:", canonicalUrl);

  return (
    <>
      <Helmet>
        <title>
          Top Real Estate Builders & Developers in Mumbai | JSB Home Makers
        </title>
        <meta
          name="description"
          content="Connect with JSB Home Makers the most reputable real estate builders and developers in Mumbai for both luxury and budget-friendly housing solutions. Find your perfect home today."
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {hasUTMParams ? (
        <HeaderVersion
          // scrollToSection={scrollToSection}
          homeRef={homeRef}
          overviewRef={overviewRef}
          amenitiesRef={amenitiesRef}
          floorPlanRef={floorPlanRef}
          locationRef={locationRef}
          galleryRef={galleryRef}
          enquireRef={enquireRef}
        />
      ) : (
        <Header
          aboutRef={aboutRef}
          testimonialsRef={testimonialsRef}
          contactRef={contactRef}
          masterpiecesRef={masterpiecesRef}
          homeTopRef={homeTopRef}
          CompletedProjectsRef={projectsRef}
          otherProjectsRef={otherProjectsRef}
          introductionRef={introductionRef}
        />
      )}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomePage scrollToSection={() => scrollToSection(contactRef)} />
              <IntroductionSection ref={introductionRef} />
              <InfoCards
                scrollToSection={scrollToSection}
                sectionRefs={{
                  projectsRef,
                  masterpiecesRef,
                }}
              />
              <MeetJSBExperts ref={aboutRef} />
              <MasterpiecesSection ref={masterpiecesRef} />
              <CompletedProjects ref={projectsRef} />
              <Footer
                scrollToSection={scrollToSection}
                ref={contactRef}
                refs={{
                  introductionRef,
                  testimonialsRef,
                  aboutRef,
                  masterpiecesRef,
                  contactRef,
                }}
              />
              <EnquireNowButton />
            </>
          }
        />
        <Route
          path="/projects/:projectId/*"
          element={
            <>
              <ProjectsPage otherProjectsRef={otherProjectsRef} />
              <Footer
                scrollToSection={scrollToSection}
                ref={contactRef}
                refs={{
                  introductionRef,
                  testimonialsRef,
                  aboutRef,
                  masterpiecesRef,
                  contactRef,
                }}
              />
              <EnquireNowButton />
            </>
          }
        />
        <Route
          exact
          path="/thank-you"
          element={
            <>
              <div className="blur-background">
                <HomePage />
              </div>
              <ThankYouPage />
              {/* No Footer here */}
            </>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <>
              <div>
                <PrivacyPolicy />
              </div>
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
