import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "../styles/Header.css";
// import logo from "../images/JSB_logo.svg";
import logo from "../images/JSB_Logo_latest.png";

const Header = ({
  aboutRef,
  testimonialsRef,
  contactRef,
  masterpiecesRef,
  homeTopRef,
  introductionRef,
  CompletedProjectsRef,
  otherProjectsRef,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeLink, setActiveLink] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const hasUTMParams = !!utmSource || !!utmMedium || !!utmId || !!gadSource;


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      const sections = [
        { ref: homeTopRef, id: "home" },
        { ref: introductionRef, id: "about" },
        { ref: masterpiecesRef, id: "projects" },
        { ref: testimonialsRef, id: "testimonials" },
        { ref: contactRef, id: "contact" },
        { ref: CompletedProjectsRef, id: "projects" },
        { ref: otherProjectsRef, id: "projects" },
        { ref: aboutRef, id: "about" } // MeetJSBExperts section
      ];

      let currentActiveLink = "";

      if (scrollPosition < sections[1].ref.current?.offsetTop) {
        currentActiveLink = "home";
      } else {
        for (const section of sections) {
          if (section.ref.current) {
            const sectionTop = section.ref.current.offsetTop;
            const sectionBottom = sectionTop + section.ref.current.offsetHeight;

            if (
              scrollPosition >= sectionTop &&
              scrollPosition < sectionBottom
            ) {
              currentActiveLink = section.id;
            }
          }
        }
      }

      // Ensure "about" is highlighted when scrolling through either IntroductionSection or MeetJSBExperts
      if (
        (scrollPosition >= introductionRef.current?.offsetTop &&
          scrollPosition <
            introductionRef.current.offsetTop +
              introductionRef.current.offsetHeight) ||
        (scrollPosition >= aboutRef.current?.offsetTop &&
          scrollPosition <
            aboutRef.current.offsetTop + aboutRef.current.offsetHeight)
      ) {
        currentActiveLink = "about";
      }

      // Debugging log to check scroll positions and active link
      // console.log("Scroll Position:", scrollPosition);
      // console.log("Active Link:", currentActiveLink);

      setActiveLink(currentActiveLink);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger scroll handler on mount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    homeTopRef,
    introductionRef,
    masterpiecesRef,
    testimonialsRef,
    contactRef,
    CompletedProjectsRef,
    otherProjectsRef,
    aboutRef,
  ]);

  const SCROLL_OFFSET = 70;
  const handleScrollToSection = (sectionRef, linkName, offset = 70) => {
    if (sectionRef && sectionRef.current) {
      setActiveLink(linkName);
      window.scrollTo({
        top: sectionRef.current.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  const handleNavigation = (sectionRef, linkName) => {
    

    const isProjectPage = location.pathname.includes("/projects/");

    if (linkName === "contact") {
      if (isProjectPage) {
        const formElement = document.querySelector(".request-callback-form");
        if (formElement) {
          window.scrollTo({
            top: formElement.offsetTop - SCROLL_OFFSET,
            behavior: "smooth",
          });
        }
      } else if (sectionRef && sectionRef.current) {
        handleScrollToSection(sectionRef, linkName, SCROLL_OFFSET);
      }
      return;
    }

    if (location.pathname === "/") {
      if (linkName === "home") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const offset = linkName === "contact" ? 150 : SCROLL_OFFSET;
        handleScrollToSection(sectionRef, linkName, offset);
      }
    } else {
      navigate("/");
      setTimeout(() => {
        if (linkName === "home") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const offset = linkName === "contact" ? 150 : SCROLL_OFFSET;
          handleScrollToSection(sectionRef, linkName, offset);
        }
      }, 300);
    }
  };

  const handleLogoClick = () => {
    if (!hasUTMParams) {
      handleNavigation(null, "home");
    } else {
      console.log("Logo click disabled due to UTM parameters.");
    }
  };

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  const handleProjectsNavigation = (projectType) => {
    setActiveLink("projects");

    let targetRef;
    if (projectType === "ongoing") {
      targetRef = masterpiecesRef;
    } else if (projectType === "completed") {
      targetRef = CompletedProjectsRef;
    }

    handleNavigation(targetRef, "projects");
  };

  return (
    <>
      {!isMobile ? (
        <header className="header">
          <img
            src={logo}
            alt="Logo"
            className="left-logo"
            onClick={handleLogoClick}
            style={{ cursor: hasUTMParams ? "not-allowed" : "pointer" }}
          />
          <div className="nav-container">
            <nav className="nav-links">
              <NavLink
                to="/"
                onClick={(e) => {
                  setActiveLink("home");
                  handleNavigation(null, "home");
                }}
                className={activeLink === "home" ? "active-link" : ""}
              >
                HOME
              </NavLink>

              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation(introductionRef, "about");
                }}
                className={activeLink === "about" ? "active-link" : ""}
              >
                ABOUT US
              </NavLink>
              <div
                className="projects-dropdown"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <span
                  className={`nav-link ${
                    activeLink === "projects" ? "active-link" : ""
                  }`}
                >
                  PROJECTS
                </span>
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <a
                      href="#ongoing"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProjectsNavigation("ongoing");
                      }}
                      className="dropdown-item"
                    >
                      ONGOING
                    </a>
                    <a
                      href="#completed"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProjectsNavigation("completed");
                      }}
                      className="dropdown-item"
                    >
                      COMPLETED
                    </a>
                  </div>
                )}
              </div>
              <NavLink
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigation(contactRef, "contact");
                }}
                className={activeLink === "contact" ? "active-link" : ""}
              >
                ENQUIRE NOW
              </NavLink>
            </nav>
          </div>

          <img src={logo} alt="Logo" className="right-logo right-logo-hide" />
        </header>
      ) : (
        <div className="mobile-footer">
          <nav className="mobile-nav-links">
            <NavLink
              to="/"
              onClick={(e) => {
                if (hasUTMParams) {
                  e.preventDefault();
                  console.log(
                    "Navigation to HOME disabled due to UTM parameters."
                  );
                } else {
                  setActiveLink("home");
                  handleNavigation(null, "home");
                }
              }}
              className={activeLink === "home" ? "active-link" : ""}
              style={{ pointerEvents: hasUTMParams ? "none" : "auto" }}
            >
              HOME
            </NavLink>
            <a
              href="#about"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(introductionRef, "about");
              }}
              className={activeLink === "about" ? "active-link" : ""}
            >
              ABOUT US
            </a>
            <div
              className="projects-dropdown-mobile"
              onClick={toggleMobileDropdown}
            >
              <span
                className={`nav-link-mobile ${
                  activeLink === "projects" ? "active-link" : ""
                }`}
              >
                PROJECTS
              </span>
              {isMobileDropdownOpen && (
                <div className="dropdown-menu-mobile">
                  <a
                    href="#ongoing"
                    onClick={(e) => {
                      e.preventDefault();
                      handleProjectsNavigation("ongoing");
                    }}
                    className="dropdown-item"
                  >
                    ONGOING
                  </a>
                  <a
                    href="#completed"
                    onClick={(e) => {
                      e.preventDefault();
                      handleProjectsNavigation("completed");
                    }}
                    className="dropdown-item"
                  >
                    COMPLETED
                  </a>
                </div>
              )}
            </div>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(contactRef, "contact");
              }}
              className={activeLink === "contact" ? "active-link" : ""}
            >
              CONTACT
            </a>
          </nav>
        </div>
      )}
    </>
  );
};

export default Header;