import React, { useState, useEffect, forwardRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/FloorPlans.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import callImage from "../images/callImage.png";
// import floorPlan1 from "../images/Veda1_Floor_Plan_image1.png";
// import floorPlan2 from "../images/Veda1_Floor_Plan_image2.png";
// import floorPlan3 from "../images/Veda1_Floor_Plan_image3.png";

// import floorPlan1 from "../images/1BHK-Floor-Plan.png";
// import floorPlan2 from "../images/2BHK-Floor-Plan.png";
// import floorPlan3 from "../images/3BHK-Floor-Plan.png";

import Veda_Floor_Plan_Image1 from "../images/nakshatra-veda-floor-plan1.jpg";
import Veda_Floor_Plan_Image2 from "../images/nakshatra-veda-floor-plan2.jpg";
import Veda_Floor_Plan_Image3 from "../images/nakshatra-veda-floor-plan3.jpg";
import Veda_Floor_Plan_Image4 from "../images/nakshatra-veda-floor-plan4.jpg";
import Veda_Floor_Plan_Image5 from "../images/nakshatra-veda-floor-plan5.jpg";

import Veda_I from "../images/Veda_I.jpeg";
import Veda_II from "../images/Veda_II.jpeg";

import leftArrow from "../images/left-arrow.png";
import rightArrow from "../images/right-arrow.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Aazstha_FloorPlan_image1 from "../images/Aazstha_FloorPlan_image1.jpg";
import Aazstha_FloorPlan_image2 from "../images/Aazstha_FloorPlan_image2.jpg";
import Aazstha_FloorPlan_image3 from "../images/Aazstha_FloorPlan_image3.jpg";
import Aazstha_FloorPlan_image4 from "../images/Aazstha_FloorPlan_image4.jpg";
import Aazstha_FloorPlan_image5 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image6 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image7 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image8 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image9 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image10 from "../images/Aazstha_FloorPlan_image5.jpg";
import Aazstha_FloorPlan_image11 from "../images/Aazstha_FloorPlan_image5.jpg";

import Gokul_FloorPlan_image1 from "../images/Gokul_FloorPlan_image1.jpg";
import Gokul_FloorPlan_image2 from "../images/Gokul_FloorPlan_image2.jpg";

import Prithvi_FloorPlan_image1 from "../images/Prithvi_FloorPlan_image1.png";
import Prithvi_FloorPlan_image2 from "../images/Prithvi_FloorPlan_image2.png";
import Prithvi_FloorPlan_image3 from "../images/Prithvi_FloorPlan_image3.png";
import Prithvi_FloorPlan_image4 from "../images/Prithvi_FloorPlan_image4.png";
import Prithvi_FloorPlan_image5 from "../images/Prithvi_FloorPlan_image5.png";

import Aarambh_FloorPlan_image1 from "../images/Aarambh_FloorPlan_image1.png";
import Aarambh_FloorPlan_image2 from "../images/Aarambh_FloorPlan_image2.png";

import ShreeRam_FloorPlan_image1 from "../images/ShreeRam_FloorPlan_image1.png";
import ShreeRam_FloorPlan_image2 from "../images/ShreeRam_FloorPlan_image2.png";
import ShreeRam_FloorPlan_image3 from "../images/ShreeRam_FloorPlan_image3.png";
import ShreeRam_FloorPlan_image4 from "../images/ShreeRam_FloorPlan_image4.png";
import ShreeRam_FloorPlan_image5 from "../images/ShreeRam_FloorPlan_image5.png";
import ShreeRam_FloorPlan_image6 from "../images/ShreeRam_FloorPlan_image6.png";

import Auris_FloorPlan_image1 from "../images/Auris_FloorPlan_image1.png";
import Auris_FloorPlan_image2 from "../images/Auris_FloorPlan_image2.png";
import Auris_FloorPlan_image3 from "../images/Auris_FloorPlan_image3.png";
import Auris_FloorPlan_image4 from "../images/Auris_FloorPlan_image4.png";

import Aazstha_QR from "../images/Aazstha_QR.jpeg";
import Gokul_QR from "../images/Gokul_QR.jpeg";
import Prithvi_QR from "../images/Prithvi_QR.jpeg";
import ShreeRam_QR from "../images/ShreeRam_QR.jpeg";
import Veda_I_QR from "../images/Veda_I.jpeg";
import Veda_II_QR from "../images/Veda_II.jpeg";

const FloorPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();

  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomed, setZoomed] = useState(false);
  const [utmParams,setUtmParams]=useState({});
  const queryParams = new URLSearchParams(location.search);
  const utmSource = queryParams.get("utm_source");
  const utmMedium = queryParams.get("utm_medium");
  const utmId = queryParams.get("utm_id");
  const gadSource = queryParams.get("gad_source");
  const queryParameters={
    utm_source: utmSource || "_",
    utm_medium: utmMedium || "_", 
    gad_source: gadSource || "_",
    utm_id: utmId || "_",
  };
  console.log("Query params---",queryParams,utmSource,utmMedium,utmId,gadSource);
  React.useEffect(() => {
    const validKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_content",
      "utm_term",
    ];
  
    const utmParams = validKeys.reduce((acc, key) => {
      const value = queryParams.get(key);
      if (value) acc[key] = value.trim(); 
      return acc;
    }, {});
  
    setUtmParams(utmParams);
  }, [location.search]);
  const queryData = Object.fromEntries(queryParams.entries());  




  const [formData, setFormData] = useState({
    name: "",
    contactNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});
  const projectOptions = [
    { value: "Nakshatra Veda Phase I", label: "Nakshatra Veda Phase I" },
    { value: "Nakshatra Veda Phase II", label: "Nakshatra Veda Phase II" },
    { value: "Nakshatra Aazstha", label: "Nakshatra Aazstha" },
    { value: "Nakshatra Gokul", label: "Nakshatra Gokul" },
    { value: "Nakshatra Prithvi", label: "Nakshatra Prithvi" },
    { value: "Nakshatra Aarambh", label: "Nakshatra Aarambh" },
    { value: "Shree Ram Nagar Complex", label: "Shree Ram Nagar Complex" },
    { value: "Nakshatra Auris", label: "Nakshatra Auris" },
  ];

  const projectIdToName = {
    "nakshatra-veda-i": "Nakshatra Veda Phase I",
    "nakshatra-veda-ii": "Nakshatra Veda Phase II",
    "nakshatra-aazstha": "Nakshatra Aazstha",
    "nakshatra-gokul": "Nakshatra Gokul",
    "nakshatra-prithvi": "Nakshatra Prithvi",
    "nakshatra-aarambh": "Nakshatra Aarambh",
    "shree-ram-nagar": "Shree Ram Nagar Complex",
    "nakshatra-auris": "Nakshatra Auris",
  };
  useEffect(() => {
    if (projectId && projectIdToName[projectId]) {
      setFormData((prevData) => ({
        ...prevData,
        project: projectIdToName[projectId],
      }));
    }
  }, [projectId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "name") {
      newValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 100);
    }

    if (name === "contactNumber") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    if (name === "email") {
      newValue = value.replace(/[^a-zA-Z0-9._%+-@]/g, "").slice(0, 100);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, project, contactNumber, email } = formData;

    if (!name) newErrors.name = "Name is required.";
    if (!project) newErrors.project = "Project selection is required.";
    if (!contactNumber) newErrors.contactNumber = "Contact number is required.";
    if (!email) newErrors.email = "Email is required.";

    // Validate name (max 100 characters, no special characters or emojis)
    if (name) {
      if (name.length > 100) {
        newErrors.name = "Name must be less than 100 characters.";
      } else if (!/^[a-zA-Z\s]*$/.test(name)) {
        newErrors.name = "Name cannot contain special characters or emojis.";
      }
    }

    // Validate email format
    if (
      email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      newErrors.email = "Email is invalid.";
    }

    // Validate contact number (must be exactly 10 digits)
    if (contactNumber && !/^\d{10}$/.test(contactNumber)) {
      newErrors.contactNumber = "Contact number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const payload = {
      ...formData,
      ...queryData,
    };
    const queryString = new URLSearchParams(queryParameters).toString();
    console.log("queryString:===== " ,queryString)

    // Log form data to check the values
    console.log(formData);
    fetch(`https://api-dynamodb.shvx.in/api/v1/lead/create?${queryString}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer etZ96daTEo2M", //Live
          // Authorization: "Bearer nKhakeSNkAVc", //dummy for testing
        },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
        })
        .catch(() => console.log("Submission failed"));

        fetch(`https://api.shvx.in/api/v1/lead/create?${queryString}`, {
          method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S", //Live
        // Authorization: "Bearer 0eUjiXPjrSYy", //dummy for testing
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("OK");
          if (window.gtag_report_conversion) {
            console.log("Triggering Google Ads Conversion");
            window.gtag_report_conversion();
            console.log("Google Ads Conversion Triggered");
          }
          
          setFormData({
            name: "",
            project: "",
            contactNumber: "",
            email: "",
          });
          setErrors({});
          navigate("/thank-you?formType=callback");
        } else {
          console.log("FAILED");
        }
      })
      .catch(() => console.log("FAILED"));
  };

  const floorPlans = {
    "nakshatra-veda-i": [
      {
        src: Veda_Floor_Plan_Image1,
        alt: "Veda Floor Plan 1",
        text: "Veda Floor Plan 1",
      },
      {
        src: Veda_Floor_Plan_Image2,
        alt: "Veda Floor Plan 2",
        text: "Veda Floor Plan 2",
      },
      {
        src: Veda_Floor_Plan_Image3,
        alt: "Veda Floor Plan 2",
        text: "Veda Floor Plan 2",
      },
      {
        src: Veda_Floor_Plan_Image4,
        alt: "Veda Floor Plan 2",
        text: "Veda Floor Plan 2",
      },
      {
        src: Veda_Floor_Plan_Image5,
        alt: "Veda Floor Plan 2",
        text: "Veda Floor Plan 2",
      },
      // Add more images as needed
    ],
    "nakshatra-aazstha": [
      {
        src: Aazstha_FloorPlan_image10,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image2,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image3,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image4,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image5,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image6,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image7,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image8,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image9,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image1,
        alt: "Floor Plan ",
        text: "Floor Plan ",
      },
      {
        src: Aazstha_FloorPlan_image11,
        alt: "Floor Plan 11 ",
        text: "Floor Plan ",
      },
    ],
    "nakshatra-gokul": [
      {
        src: Gokul_FloorPlan_image1,
        alt: "Floor Plan Gokul 1",
        text: "Floor Plan Gokul 1",
      },
      {
        src: Gokul_FloorPlan_image2,
        alt: "Floor Plan Gokul 2",
        text: "Floor Plan Gokul 2",
      },
    ],

    "nakshatra-prithvi": [
      {
        src: Prithvi_FloorPlan_image1,
        alt: "Floor Plan Prithvi 1",
        text: "Floor Plan Prithvi 1",
      },
      {
        src: Prithvi_FloorPlan_image2,
        alt: "Floor Plan Prithvi 2",
        text: "Floor Plan Prithvi 2",
      },
      {
        src: Prithvi_FloorPlan_image3,
        alt: "Floor Plan Prithvi 3",
        text: "Floor Plan Prithvi 3",
      },
      {
        src: Prithvi_FloorPlan_image4,
        alt: "Floor Plan Prithvi 4",
        text: "Floor Plan Prithvi 4",
      },
      {
        src: Prithvi_FloorPlan_image5,
        alt: "Floor Plan Prithvi 5",
        text: "Floor Plan Prithvi 5",
      },
    ],

    "nakshatra-aarambh": [
      {
        src: Aarambh_FloorPlan_image1,
        alt: "Floor Plan Aarambh 1",
        text: "Floor Plan Aarambh 1",
      },
      {
        src: Aarambh_FloorPlan_image2,
        alt: "Floor Plan Aarambh 2",
        text: "Floor Plan Aarambh 2",
      },
    ],

    "shree-ram-nagar": [
      {
        src: ShreeRam_FloorPlan_image1,
        alt: "ShreeRam floorPlan 1",
        text: "Shree Ram FloorPlan 1",
      },
      {
        src: ShreeRam_FloorPlan_image2,
        alt: "ShreeRam floorPlan 2",
        text: "Shree Ram FloorPlan 2",
      },
      {
        src: ShreeRam_FloorPlan_image3,
        alt: "ShreeRam floorPlan 3",
        text: "Shree Ram FloorPlan 3",
      },
      {
        src: ShreeRam_FloorPlan_image4,
        alt: "ShreeRam floorPlan 4",
        text: "Shree Ram FloorPlan 4",
      },
      {
        src: ShreeRam_FloorPlan_image5,
        alt: "ShreeRam floorPlan 5",
        text: "Shree Ram FloorPlan 5",
      },
      {
        src: ShreeRam_FloorPlan_image6,
        alt: "ShreeRam floorPlan 6",
        text: "Shree Ram FloorPlan 6",
      },
    ],
    "nakshatra-auris": [
      {
        src: Auris_FloorPlan_image1,
        alt: "Floor Plan Auris 1",
        text: "Flor Plan For Nakshatra Auris 1",
      },
      {
        src: Auris_FloorPlan_image2,
        alt: "Floor Plan Auris 2",
        text: "Flor Plan For Nakshatra Auris 2",
      },
      {
        src: Auris_FloorPlan_image3,
        alt: "Floor Plan Auris 3",
        text: "Flor Plan For Nakshatra Auris 3",
      },
      {
        src: Auris_FloorPlan_image4,
        alt: "Floor Plan Auris 4",
        text: "Flor Plan For Nakshatra Auris 4",
      },
    ],
  };

  const mapUrls = {
    "nakshatra-veda-i":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.4333547378938!2d72.84477131092112!3d19.39367334184698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7af5eba5c0a49%3A0xc9218175bbe48!2sYashwant%20Smart%20City!5e0!3m2!1sen!2sin!4v1723270985949!5m2!1sen!2sin",
    "nakshatra-aazstha":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3763.1835865188814!2d72.8259167!3d19.4044722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDI0JzE2LjEiTiA3MsKwNDknMzMuMyJF!5e0!3m2!1sen!2sin!4v1723619959661!5m2!1sen!2sin",
    "nakshatra-gokul":
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.0799892038813!2d72.8076323!3d19.4521178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a9e117835c75%3A0xa50c8f7fb2fcbbfb!2sNakshatra%20Gokul!5e0!3m2!1sen!2sin!4v1723784058724!5m2!1sen!2sin",
    "nakshatra-prithvi":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3763.1835865188814!2d72.8259167!3d19.4044722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDI0JzE2LjEiTiA3MsKwNDknMzMuMyJF!5e0!3m2!1sen!2sin!4v1723791203599!5m2!1sen!2sin",
    "nakshatra-aarambh":
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3764.102074758767!2d72.8601625752126!3d19.36473238190025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDIxJzUzLjAiTiA3MsKwNTEnNDUuOSJF!5e0!3m2!1sen!2sin!4v1723802391029!5m2!1sen!2sin",
    "shree-ram-nagar":
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15048.22898514741!2d72.798578!3d19.4530985!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7abba83d05655%3A0xaadcebe88e8bd44d!2sShreeRam%20Complex!5e0!3m2!1sen!2sin!4v1724930039375!5m2!1sen!2sin",
    "nakshatra-auris":
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15050.997714764659!2d72.8049024!3d19.4232295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a95b21e3e697%3A0x4a90644a230b294b!2sNakshatra%20Auris!5e0!3m2!1sen!2sin!4v1724931972656!5m2!1sen!2sin",
  };

  // const brochureUrls = {
  //   "nakshatra-veda-i":
  //     "https://drive.google.com/file/d/1SAxJRukjCJcCj5l1vuVCbTSU-9z8LLWk/view",
  //   "nakshatra-aazstha":
  //     "https://drive.google.com/file/d/1DTuf6fhQDhviFkHR8H_O4P7JPqbCco4C/view?usp=sharing",
  //   "nakshatra-gokul":
  //     "https://drive.google.com/file/d/1cj2wMpgOr5YAw-hbpjSQ-L9uPNbsvaIa/view?usp=sharing",
  //   "nakshatra-prithvi":
  //     "https://drive.google.com/file/d/1Gyhotnekk9L80ZSyvgc1F1NbN1TeBZEF/view?usp=sharing",
  //   "nakshatra-aarambh":
  //     "https://drive.google.com/file/d/1rKi5iXb7l5T41cYq1ze-pq-Sx9GERtHz/view?usp=sharing",
  //   "shree-ram-nagar":
  //     "https://drive.google.com/file/d/1hQiSQ9UA5jjaSTYrIk7uJVsx-Iv9Re7o/view?usp=sharing",
  //   "nakshatra-auris":
  //     "https://drive.google.com/file/d/1ThFkE_mG0YZKr1y9JSVs8pYAWguU9RxQ/view?usp=sharing",
  // };

  const qrCodeUrls = {
    "nakshatra-veda-i": [Veda_I_QR, Veda_II_QR],
    "nakshatra-aazstha": [Aazstha_QR],
    "nakshatra-gokul": [Gokul_QR],
    "nakshatra-prithvi": [Prithvi_QR],
    "shree-ram-nagar": [ShreeRam_QR],
    // "nakshatra-auris": ,
  };
  const reraDetails = {
    "nakshatra-veda-i": {
      text: [
        "  MahaRERA Reg No: Nakshatra Veda I & II is registered with MahaRERA bearing RERA NO. P99000047826",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-aazstha": {
      text: [
        "MahaRERA Reg No: Nakshatra Aazstha is registered with MahaRERA bearing RERA NO. P99000051584",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },

    "nakshatra-gokul": {
      text: [
        "MahaRERA Reg No: Nakshatra Gokul is registered with MahaRERA bearing RERA NO. P99000056045",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-prithvi": {
      text: [
        "MahaRERA Reg No: Nakshatra Prithvi is registered with MahaRERA bearing RERA NO. P99000055701",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-aarambh": {
      text: [
        "MahaRERA Reg No: Nakshatra Aarambh is registered with MahaRERA bearing RERA NO. P99000027563",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "shree-ram-nagar": {
      text: [
        "MahaRERA Reg No: shree Ram Nagar Complex is registered with MahaRERA bearing RERA NO. P99000048744",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
    "nakshatra-auris": {
      text: [
        "MahaRERA Reg No: Nakshatra Auris is registered with MahaRERA bearing RERA NO. P99000053022",
        "For further details please visit: http://maharera.mahaonline.gov.in.",
        "*T&C Apply",
      ],
    },
  };

  const currentFloorPlans = floorPlans[projectId] || [];
  const mapUrl = mapUrls[projectId] || "";
  // const brochureUrl = brochureUrls[projectId] || "";
  // const qrCodeUrl = qrCodeUrls[projectId] || "";
  const currentQRCodes = qrCodeUrls[projectId] || [];
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsFormModalOpen(true);
  };

  const handleFormClose = () => {
    setIsFormModalOpen(false);
    setSelectedImage(null);
  };
  // const handleImageClick = (imageSrc) => {
  //   setSelectedImage(imageSrc);
  //   setZoomed(false); // Reset zoom state
  // };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams(queryParameters).toString();
    console.log("queryString:===== " ,queryString)

    // Extract project ID from the URL
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const projectId = pathSegments.includes("projects")
      ? pathSegments[pathSegments.indexOf("projects") + 1]
      : null;

    if (!projectId) {
      alert("Invalid URL: Unable to determine project ID.");
      return;
    }

    // Prepare the payload with form data, project ID, and selected image
    const payload = {
      ...formData,
      projectId: projectId.replace(/-/g, " "), // Convert hyphens to spaces if needed
      selectedImage: selectedImage, // Include the selected image
    };
    fetch(`https://api-dynamodb.shvx.in/api/v1/lead/create?${queryString}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer etZ96daTEo2M", //Live
          // Authorization: "Bearer nKhakeSNkAVc", //dummy for testing
        },
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
        })
        .catch(() => console.log("Submission failed"));
    // Send data to the API
    fetch(`https://api.shvx.in/api/v1/lead/create?${queryString}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer eGzFywzn9i0S", //Live
        // Authorization: "Bearer n46aDZzLC4MR", //dummy for testing
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          alert("Form submitted successfully!");
          setFormData({ name: "", contactNumber: "", email: "" }); // Reset form data
          setErrors({});
          setIsFormModalOpen(false); // Close the form modal
          setZoomed(false);
        } else {
          alert(response.message || "Failed to submit data. Please try again.");
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again.");
      });
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setZoomed(false); // Reset zoom state
  };

  const handleImageZoom = (e) => {
    e.stopPropagation();
    setZoomed((prevZoom) => !prevZoom);
  };
  return (
    <>
      <div className="floor-plans">
        <h2 className="resources-title">RESOURCES</h2>
        <p id="floor-plans" className="floor-plans-title">
          Floor Plans
        </p>
        <Slider {...settings}>
          {currentFloorPlans.map((plan, index) => (
            <div key={index} onClick={() => handleImageClick(plan.src)}>
              <img src={plan.src} alt={plan.alt} className="floor-plan-image" />
            </div>
          ))}
        </Slider>

        <Modal open={isFormModalOpen} onClose={handleFormClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
            }}
          >
            <h3
              style={{
                color: "#dca52e",
                fontSize: "1.2rem",
                fontWeight: 600,
                padding: "10px",
              }}
            >
              Get Floor Plan
            </h3>
            {/* <img
              src={selectedImage}
              alt="Full Size"
              style={{ width: "100%", marginBottom: "20px" }}
            /> */}
            <form onSubmit={handleFormSubmit}>
              <TextField
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                name="contactNumber"
                label="Contact Number"
                value={formData.contactNumber}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                name="email"
                label="Email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  backgroundColor: "#dca52e",
                  "&:hover": { backgroundColor: "#c49529" },
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>

        {selectedImage && !isFormModalOpen && (
          <div
            className="floor-plans-veda1-modal-overlay"
            onClick={handleCloseModal}
          >
            <div className="floor-plans-veda1-modal-content">
              <span
                className="floor-plans-veda1-close-btn"
                onClick={handleCloseModal}
              >
                ×
              </span>
              <img
                src={selectedImage}
                alt="Full Size"
                className={`floor-plans-veda1-modal-image ${
                  zoomed ? "zoomed" : ""
                }`}
                onClick={handleImageZoom}
              />
            </div>
          </div>
        )}

        {/* {brochureUrl && (
          <a
            className="download-brochure"
            target="_blank"
            href={brochureUrl}
            download
          >
            Click Here to download Brochure
          </a>
        )} */}
        <div id="location" className="Project-location-container">
          <h1 className="floor-plans-project-location-title">
            PROJECT LOCATION
          </h1>
          <div>
            {mapUrl && (
              <iframe
                src={mapUrl}
                style={{ border: "0", borderRadius: "0px 40px 0px 0px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Location"
              />
            )}
          </div>
        </div>
        <div className="rera-details-title-container">
          <h1 id="enquire" className="rera-details-title">
            RERA DETAILS
          </h1>
        </div>
        <div className="rera-details-wrapper">
          <div className="rera-details-container">
            {reraDetails[projectId] && (
              <>
                {reraDetails[projectId].text.map((line, index) => (
                  <p key={index}>
                    {index === 1 ? (
                      <>
                        {line.split("http://maharera.mahaonline.gov.in")[0]}
                        <a
                          href="http://maharera.mahaonline.gov.in"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="rera-link"
                        >
                          http://maharera.mahaonline.gov.in
                        </a>
                        {line.split("http://maharera.mahaonline.gov.in")[1]}
                      </>
                    ) : (
                      <span>{line}</span>
                    )}
                  </p>
                ))}
              </>
            )}
          </div>

          {currentQRCodes.length > 0 ? (
            currentQRCodes.map((qrCodeUrl, index) => (
              <img
                key={index}
                src={qrCodeUrl}
                alt={`QR Code ${index + 1} for ${projectId}`}
                className="floorplan-aazastha-qr-code-image"
              />
            ))
          ) : (
            <p>No QR codes available for this project.</p>
          )}
        </div>
        {/* <h1 className="enquiry-form-title-veda">ENQUIRE NOW</h1> */}
      </div>
      <div className="request-callback-form">
        <div>
          <h1>ENQUIRE NOW</h1>
        </div>
        <div className="callback-form-container">
          <form className="callback-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <div className="error">{errors.name}</div>}

            {projectId ? (
              <input
                type="text"
                name="project"
                value={formData.project}
                readOnly
                className="readonly-input"
              />
            ) : (
              <select
                name="project"
                value={formData.project}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Interested in which project
                </option>
                {projectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
            {errors.project && <div className="error">{errors.project}</div>}

            <input
              type="text"
              placeholder="Contact Number*"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
            {errors.contactNumber && (
              <div className="error">{errors.contactNumber}</div>
            )}
            <input
              type="email"
              placeholder="Email ID*"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className="error">{errors.email}</div>}

            <button type="submit">REQUEST CALLBACK</button>
          </form>
        </div>
      </div>
    </>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="next-slide-arrow" onClick={onClick}>
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="prev-slide-arrow" onClick={onClick}>
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

export default FloorPlans;
